/* globals iframemanager */
import strings from '../../config/embeds-consent-i18n.json';

(() => {
  const im = iframemanager();
  im.run({
    currLang: $('html').attr('lang') || 'en',
    services: {
      youtube: {
        embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
        thumbnailUrl: '/assets/img/thumbnails/youtube-{data-id}.jpg',
        iframe: {
          allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share',
        },
        languages: strings.embedsConsent.i18n.youtube,
      },
      vimeo: {
        embedUrl: 'https://player.vimeo.com/video/{data-id}',
        thumbnailUrl: '/assets/img/thumbnails/vimeo-{data-id}.jpg',
        iframe: {
          allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share',
        },
        languages: strings.embedsConsent.i18n.vimeo,
      },
    },
  });

  $('[data-component=embeds-consent-withdraw]').each((i, el) => {
    const consentState = im.getState();
    const toggles = [];

    const component = $('<div></div>')
      .addClass('embeds-consent-withdraw');
    // consentState.services is a Map<string, boolean>
    // Iterate over its entries.
    consentState.services.entries().forEach(([service, state]) => {
      console.log(service, state);
      const toggle = $('<input></input>')
        .addClass('form-check-input')
        .attr('type', 'checkbox')
        .attr('role', 'switch')
        .attr('id', `embeds-consent-withdraw-${service}`)
        .prop('checked', state)
        .on('change', (ev) => {
          if (ev.target.checked) {
            im.acceptService(service);
          } else {
            im.rejectService(service);
          }
        });
      toggles.push(toggle);
      component.append(
        $('<div></div>').addClass('form-check form-switch')
          .append(toggle)
          .append(
            $('<label></label>')
              .addClass('form-check-label')
              .attr('for', `embeds-consent-withdraw-${service}`)
              .text(`Show ${strings.embedsConsent.serviceNames[service] ?? service} content`)
          )
      );
    });
    component.append(
      $('<button></button>').addClass('btn btn-sm btn-outline-danger mt-3')
        .text('Don\'t show any third-party content')
        .on('click', () => {
          im.rejectService('all');
          toggles.forEach((t) => {
            t.prop('checked', false);
          });
        })
    );

    $(el).replaceWith(component);
  });
})();
