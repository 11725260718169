import baseConfig from '../../config.json';

const config = {
  ...baseConfig,
  ...{
    staticFileBasepath: process.env.STATIC_FILE_BASEPATH || '',
    staticFile: (path) => `${config.staticFileBasepath}/${path}`,
    gmAPIKey: process.env.GOOGLE_MAPS_API_KEY || '',
    mapboxAPIKey: process.env.MAPBOX_API_KEY || '',
  },
};

if (window.IMAGINARY === undefined) {
  window.IMAGINARY = {};
}
if (window.IMAGINARY.IDM314 === undefined) {
  window.IMAGINARY.IDM314 = {};
}

if (window.IMAGINARY.IDM314.config === undefined) {
  window.IMAGINARY.IDM314.config = config;
}

export default config;
